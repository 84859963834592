import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';

import type { Channel } from 'stream-chat';

export enum ChatSidebarTabKey {
  overview = 0,
  wishBag = 1,
  call = 2,
  survey = 3,
}

type ChatSidebarContextType = {
  setShowSideBar: (showSideBar: boolean) => void;
  showSideBar?: boolean;
  setShowScheduleTab: (showSideBar: boolean) => void;
  showScheduleTab?: boolean;
  channel?: Channel;
  setSelectedTab: (tab: ChatSidebarTabKey) => void;
  selectedTab?: ChatSidebarTabKey;
  onJoinVideoCall?: () => void;
  showCallsModal?: boolean;
};

export const ChatSidebarContext = createContext<ChatSidebarContextType>({
  setShowSideBar: () => null,
  setShowScheduleTab: () => null,
  setSelectedTab: () => null,
});

interface ChatSidebarProviderProps {
  value?: ChatSidebarContextType;
  children?: ReactElement<any, any>;
}

export const ChatSidebarProvider = ({ value, ...rest }: ChatSidebarProviderProps) => {
  const [showSideBar, setShowSideBar] = useState(value?.showSideBar);
  const [showScheduleTab, setShowScheduleTab] = useState(value?.showSideBar);
  const [selectedTab, setSelectedTab] = useState(ChatSidebarTabKey.overview);

  useEffect(() => {
    if (value?.showCallsModal) {
      setSelectedTab(ChatSidebarTabKey.call);
      setShowSideBar(true);
    }
  }, [value?.showCallsModal]);

  return (
    <ChatSidebarContext.Provider
      value={{
        ...value,
        showSideBar,
        setShowSideBar,
        showScheduleTab,
        setShowScheduleTab,
        selectedTab,
        setSelectedTab,
      }}
      {...rest}
    />
  );
};

export const useChatSidebarContext = () => useContext(ChatSidebarContext);
