import { removeFormalSuffix, useTranslation } from '@amazd/common/utils/i18n';
import { Streami18n } from 'stream-chat-react';


export const useStreamI18Instance = () => {
  const { i18n } = useTranslation();
  const lang = removeFormalSuffix(i18n.language) as 'en' | 'de' | 'nl' | 'es' | 'fr' | undefined;
  if (!lang) return;

  let localizeData = (i18n.getDataByLanguage(i18n.language) as any)?.['general']; //  formal or informal data
  if (lang !== i18n.language) {
    const informalData = (i18n.getDataByLanguage(lang) as any)?.['general'];
    //  formal keys will overwrite informal ones
    localizeData = { ...informalData, ...localizeData };
  }

  const i18Instance = new Streami18n({ language: lang });
  const defaultTranslations = i18Instance.translations[lang]['translation'];

  if (localizeData) {
    i18Instance.registerTranslation(lang, {
      ...defaultTranslations,
      'Type your message': localizeData['chat-input-placeholder'],
    } as any);
  }

  return i18Instance;
};
