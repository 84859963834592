import React from 'react';

import { ProductUnavailableLabel } from '@amazd/common/components/shopify/productUnavailableLabel';
import { MessageFooter } from '@amazd/common/components/StreamChat';
import { CustomUserMessageBoxProps } from '@amazd/common/components/StreamChat/types';
import { useAuthInfo } from '@amazd/common/hooks/auth';
import { useOpenProductPage } from '@amazd/common/hooks/useOpenProductPage';
import { useChannelPeerUser } from '@amazd/common/hooks/useStreamChannel';
import { StreamShopifyProductMessageCustomData } from '@amazd/common/redux/types/chat.types';
import { ShopProductVariantSelectedOption } from '@amazd/common/types/shop.types';
import { getCurrencySymbol } from '@amazd/common/utils/money';
import { getProductInfo } from '@amazd/common/utils/product';
import { Wish2BagButton } from 'components/Shopify/wish2BagButton';
import { useShopifyCartContext } from 'components/Shopify/wishBag/hooks/useCart';

import useStyles from './styles';

export const ShopifyProductMessageBox = ({ message, isMyMessage }: CustomUserMessageBoxProps) => {
  const classes = useStyles();

  const { ownUserType } = useAuthInfo();
  const { loading, channel } = useShopifyCartContext();
  const { isOwnUserMember } = useChannelPeerUser(channel);

  const { product, variant } = (message?.customData ?? {}) as StreamShopifyProductMessageCustomData;
  const { title, priceRangeV2, featuredImage, publishedOnCurrentPublication } = product;
  const productImageUrl = variant?.image || featuredImage;

  const { openProductPage } = useOpenProductPage(channel?.id as string);

  const onClick = () => {
    openProductPage(product, variant);
  };

  const { price, isUnavailable } = getProductInfo(product, variant);

  return (
    <div className={`${classes.root} ${isMyMessage && classes.rootIsMyMessage}`}>
      <div className={classes.messageWrapper}>
        <div className={`${classes.cardWrapper} ${isMyMessage && classes.cardWrapperIsMyMessage}`}>
          {ownUserType?.isExpert && isOwnUserMember && !isUnavailable && !loading && (
            <div className={classes.wish2Bag}>
              <Wish2BagButton product={product} variant={variant} tooltipPlacement={isMyMessage ? 'left' : 'right'} />
            </div>
          )}
          <div className={`${classes.card} ${isMyMessage && classes.cardIsMyMessage}`} onClick={onClick}>
            <div className={classes.imgWrapper}>
              <img className={classes.img} src={productImageUrl?.url} />
            </div>
            <div className={classes.infoWrapper}>
              <div className={classes.title}>{title}</div>
              <div className={classes.price}>
                {getCurrencySymbol(priceRangeV2?.minVariantPrice?.currencyCode as CurrencyCode)}
                {price}
              </div>
              {isUnavailable && (
                <div className={classes.outOfStock}>
                  <ProductUnavailableLabel
                    type={publishedOnCurrentPublication === false ? 'not-published' : 'out-of-stock'}
                  />
                </div>
              )}
              <div className={classes.options}>
                {!product.hasOnlyDefaultVariant &&
                  variant?.selectedOptions?.map((selectedOption: ShopProductVariantSelectedOption, index: number) => (
                    <span key={index.toString()}>
                      {selectedOption.name}: {selectedOption.value} <br />
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <MessageFooter message={message} isMyMessage={isMyMessage} />
      </div>
    </div>
  );
};
