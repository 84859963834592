import { makeStyles } from '@mui/styles';

import { colors } from '../../../static';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 'none',
    flexGrow: 0,
  },
  outOfStockWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 4px 3px 4px',
    borderRadius: 4,
    backgroundColor: colors.grey30,
  },
  outOfStock: {
    textAlign: 'center',
    color: colors.grey80,
    height: 13,
    fontWeight: 500,
    fontSize: 10,
  },
}));
