import React from 'react';

import { MessageFooter } from '@amazd/common/components/StreamChat';
import { CustomUserMessageBoxProps } from '@amazd/common/components/StreamChat/types';
import WishBagIcon from '@amazd/common/icons/WishBagIcon';
import { useTranslation } from '@amazd/common/utils/i18n';
import { colors } from 'styles/theme';
import { ChatSidebarTabKey, useChatSidebarContext } from 'views/InboxView/ChatSidebar/context/sidebarContext';

import useStyles from './styles';

export const ShopifyWishBagReminderMessageBox = ({ message, isMyMessage }: CustomUserMessageBoxProps) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { setShowSideBar, setSelectedTab } = useChatSidebarContext();

  const onClick = async () => {
    setSelectedTab(ChatSidebarTabKey.wishBag);
    setShowSideBar(true);
  };

  return (
    <div className={`${classes.root} ${isMyMessage && classes.rootIsMyMessage}`}>
      <div className={classes.messageWrapper}>
        <div className={classes.cardWrapper}>
          <div className={`${classes.card} ${isMyMessage && classes.cardIsMyMessage}`} onClick={onClick}>
            <div className={classes.imgWrapper}>
              <WishBagIcon color={colors.white} style={{ fontSize: 40 }} />
            </div>
            <div className={classes.infoWrapper}>
              <div className={classes.titleWrapper}>
                <div className={classes.title}>{t('stream-shopify-checkout-message-title')}</div>
              </div>
              <div className={classes.description}>{t('stream-shopify-checkout-message-description')}</div>
            </div>
          </div>
        </div>
        <MessageFooter message={message} isMyMessage={isMyMessage} />
      </div>
    </div>
  );
};
