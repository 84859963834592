interface IStorage<T> {
  getItem(): T | undefined;
  setItem(item: T): void;
  removeItem(): void;
}

export class Storage<T> implements IStorage<T> {
  constructor(private key: string) {}

  getItem() {
    const value = localStorage.getItem(this.key);
    return value ? JSON.parse(value) : '';
  }

  setItem(value: T) {
    localStorage.setItem(this.key, JSON.stringify(value));
  }

  removeItem() {
    localStorage.removeItem(this.key);
  }
}
