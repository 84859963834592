import { WishbagFragment } from '@amazd/common/hooks/useWishbagQuery';
import { gql, useMutation } from '@apollo/client';

export const useApplyDiscountMutation = () => {
  const [applyDiscountEvent, { loading }] = useMutation(
    gql`
    mutation amazdWishbagDiscountApply($args: AmazdWishBagDiscountApplyArgs!) {
      amazdWishbagDiscountApply(args: $args) {
        ${WishbagFragment}        
      }
    }
    `,
  );

  const applyDiscount = async (amazdId: string, discountCode: string) => {
    return (
      await applyDiscountEvent({
        variables: {
          args: {
            amazdId,
            discountCode,
          },
        },
      })
    ).data.amazdWishbagDiscountApply;
  };

  return {
    applyDiscount,
    loading,
  };
};
