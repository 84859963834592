import { gql } from '@apollo/client';

export const SURVEYS_BY_AMAZD_ID = gql`
  query getSurveys($amazdId: String!) {
    surveys: surveysByAmazd(amazdId: $amazdId) {
      score
      answeredAt
      id
      amazdId
      createdAt
      __typename
    }
  }
`;

export const SURVEY_BY_ID = gql`
  query getSurveyById($surveyId: String!) {
    survey: surveyById(surveyId: $surveyId) {
      score
      answeredAt
      id
      amazdId
      createdAt
      __typename
    }
  }
`;

export const CREATE_SURVEY = gql`
  mutation createSurvey($amazdId: String!) {
    createSurvey(amazdId: $amazdId) {
      score
      answeredAt
      id
      amazdId
      createdAt
      __typename
    }
  }
`;

export const ANSWER_SURVEY = gql`
  mutation answerSurvey($surveyId: String!, $score: Float!) {
    survey: answerSurvey(surveyId: $surveyId, score: $score) {
      score
      answeredAt
      id
      amazdId
      createdAt
      __typename
    }
  }
`;

export const SUBSCRIBE_TO_WHEN_SURVEY_ANSWERED = gql`
  subscription ($data: onSurveyAnsweredSubscriptionDto!) {
    onSurveyAnswered(data: $data) {
      score
      answeredAt
      id
      amazdId
      createdAt
      __typename
    }
  }
`;
