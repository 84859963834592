import { makeStyles } from '@mui/styles';

import { colors } from '../../../../static';

export default makeStyles(() => ({
  name: {
    fontWeight: 700,
    fontSize: 10,
    color: colors.grey80,
    marginRight: 4,
  },
  date: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontWeight: 400,
    fontSize: 10,
    color: colors.grey80,
    marginTop: 4,
  },
  dateIsMyMessage: {
    justifyContent: 'flex-end',
  },
}));
