import { makeStyles } from '@mui/styles';

import { CustomTheme } from '../../theme';

export default makeStyles((theme: CustomTheme) => ({
  /* FULLSCREEN IMAGE PREVIEW */
  '@global': {
    '.react-images__header_button--fullscreen': {
      display: 'none !important',
    },
    '.react-images__blanket': {
      zIndex: '2001 !important',
    },
    '.react-images__positioner': {
      zIndex: '2002 !important',
    },
  },
  chat: {
    '& button': {
      cursor: 'pointer',
    },
    '& .str-chat__modal__inner': {
      maxWidth: 'unset',
      background: 'unset',
    },
    '& .image-gallery-fullscreen-button': {
      display: 'none',
    },
    '& .str-chat__input-flat': {
      background: 'unset',
      paddingRight: '8px',
      paddingLeft: '16px',
    },
    '& .str-chat__input-flat .rfu-dropzone': {
      outline: 'none',
    },
    '& .str-chat__textarea': {
      display: 'flex',
      alignItems: 'flex-end',
    },
    '& .str-chat__textarea>textarea': {
      fontFamily: theme.typography.fontFamily,
    },
    '& .emoji-mart': {
      fontFamily: theme.typography.fontFamily,
    },
    '& .emoji-mart-search input': {
      fontFamily: theme.typography.fontFamily,
    },
    '& .str-chat.messaging': {
      background: 'unset',
    },
    '& .str-chat__message-simple-text-inner a': {
      color: 'inherit',
    },
    '& .str-chat__send-button': {
      display: 'unset !important',
    },
    '& .str-chat__input-flat .str-chat__textarea > textarea': {
      background: 'var(--white)',
      borderRadius: 'var(--border-radius)',
      paddingLeft: '57px',
    },
    '& .str-chat__input-flat .rfu-file-upload-button': {
      top: 'unset',
      bottom: '12px',
    },
    '& .str-chat__input-flat .rfu-file-previewer': {
      margin: 'var(--xs-p)',
    },
    '& .rfu-image-upload-button': {
      display: 'none',
    },
    '& .rfu-image-previewer': {
      padding: '0px var(--xs-p)',
    },
    '& .messaging.str-chat .str-chat__list': {
      ...theme.widgetBackgroundCover,
    },
    '& .str-chat__message-simple--me .str-chat__message-text-inner': {
      background: 'var(--primary-color)',
      color: 'var(--white)',
      borderRadius: 'var(--border-radius) var(--border-radius) 0 var(--border-radius)',
    },
    '& .str-chat__message-simple-name': {
      maxWidth: '150px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'inline-flex',
    },
    '& .str-chat__player-wrapper': {
      background: 'black',
    },
    '& .str-chat__gallery-placeholder': {
      width: 150,
      height: 150,
      color: 'transparent',
      cursor: 'zoom-in',
    },
    '& .str-chat__message-attachment': {
      maxWidth: 'unset',
    },
    '& .str-chat__message-attachment--file': {
      background: 'var(--primary-color)',
      color: 'var(--white)',
      borderRadius: 'var(--border-radius) var(--border-radius) 0 var(--border-radius)',
    },
    '& .str-chat__message-attachment-file--item a': {
      color: 'var(--white)',
    },
    '& .str-chat__message-attachment-file--item span': {
      color: 'var(--white)',
    },
    '& .str-chat__message-attachment-file--item a::after': {
      color: 'var(--white)',
    },
    '& .str-chat__avatar': {
      display: 'none',
    },
    '& .str-chat__message-simple-status': {
      '& .str-chat__avatar': {
        display: 'block',
      },
      ['@media screen and (max-width: 960px)']: {
        right: '-25px',
        bottom: 0,
      },
    },
    '& .str-chat__li .quoted-message-inner': {
      maxWidth: '100%',
      wordBreak: 'break-word',
    },
    '& .str-chat__li .quoted-message-inner .str-chat__message-attachment-card--header': {
      maxHeight: '48px',
    },
    '& .str-chat__input-flat-quoted .quoted-message-preview-content': {
      background: 'unset',
      maxWidth: '80%',
    },
    '& .str-chat__input-flat-quoted .quoted-message-preview-content-inner': {
      maxWidth: '100%',
      fontSize: 'var(--md-font)',
      ['@media screen and (max-width: 960px)']: {
        flexDirection: 'column',
      },
      wordBreak: 'break-word',
    },
    '& .str-chat__li .quoted-message-inner .str-chat__message-attachment img': {
      maxHeight: '150px',
    },
    '& .str-chat__input-flat-quoted .quoted-message-preview-content-inner .str-chat__message-attachment-file--item': {
      background: 'unset',
    },
    '& .str-chat__li .quoted-message-inner .str-chat__message-attachment--file': {
      background: 'var(--transparent)',
    },
    '& .str-chat__input-flat-emojiselect': {
      outline: 'none',
      border: 'none',
      background: 'transparent',
      left: '10px',
      bottom: '11px',
      top: 'unset',
    },
    '& .str-chat__message-attachment-card': {
      margin: 0,
    },
    '& .str-chat__message-text-inner': {
      padding: 'var(--spacing-unit)',
    },
    '& .str-chat__message-text-inner ul': {
      paddingLeft: '20px',
    },
    '& .str-chat__message-text-inner li': {
      textAlign: 'initial',
      wordBreak: 'break-word',
      marginBottom: '4px',
    },
    '& .str-chat__li .quoted-message.mine .quoted-message-inner': {
      background: 'var(--primary-color-faded)',
    },
    '& .str-chat__message-simple:hover .str-chat__message-simple__actions__action--options': {
      ['@media screen and (max-width: 960px)']: {
        display: 'flex',
      },
    },
    '& .str-chat__message-simple .str-chat__message-simple__actions__action--options': {
      '& button': {
        outline: 'none',
        border: 'none',
        background: 'transparent',
      },
    },
    '& .str-chat__edit-message-form .str-chat__input-fileupload svg': {
      width: '15px',
      height: '14px',
    },
    '& .str-chat__modal .str-chat__input-emojiselect': {
      width: '30px',
    },
    '& .str-chat__modal__close-button': {
      outline: 'none',
      border: 'none',
      background: 'transparent',
    },
    '& .str-chat__message-actions-list-item': {
      fontFamily: theme.typography.fontFamily,
    },
    '& .str-chat__tooltip': {
      fontFamily: theme.typography.fontFamily,
    },
    '& .str-chat__main-panel': {
      padding: '0 !important',
    },
    '& .str-chat__input-flat-wrapper': {
      // flexWrap: 'nowrap',
    },
    '& .str-chat__input-flat--textarea-wrapper': {
      maxWidth: 'calc(100% - 40px)',
      background: 'var(--white)',
      borderRadius: 'var(--border-radius)',
    },
    '& .str-chat__message-inner': {
      maxWidth: '95%',
    },
    '& .str-chat__modal .str-chat__edit-message-form': {
      maxWidth: '80vw',
    },
    '& .rfu-file-previewer': {
      background: 'var(--white)',
      marginLeft: '0 !important',
    },
    '& .str-chat__message--error, .str-chat__message--failed, .str-chat__message-simple--error, .str-chat__message-simple--failed':
      {
        margin: 'unset',
      },
    '& .str-chat__edit-message-form .str-chat__message-team-form-footer button[type=submit]': {
      color: 'white',
    },
    height: `100%`,
    maxWidth: '640px',
  },
  chatContainer: {},
  channel: {
    // width: '80%',
  },
  messageList: {
    flex: '1 1',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingLeft: 16,
    paddingRight: 36,
  },
  message: {
    background: theme.palette.primary.main,
  },
  backButton: {
    position: 'absolute',
    left: 15,
    top: 15,
    color: 'black',
    '& svg': {
      fontSize: 16,
    },
  },
  appBar: {
    background: 'white',
    boxShadow: 'none',
  },
}));
