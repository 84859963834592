import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
  },
  rootIsMyMessage: {
    justifyContent: 'flex-end',
  },
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 0px',
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    borderColor: colors.grey30,
    border: 'solid',
    borderWidth: 1,
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 16,
    padding: 16,
    width: 300,
    cursor: 'pointer',
  },
  cardIsMyMessage: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 0,
  },
  imgWrapper: {
    width: 80,
    minWidth: 80,
    height: 80,
    overflow: 'hidden',
    borderRadius: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.purple100,
  },
  img: {
    width: 80,
    height: 80,
    overflow: 'hidden',
    objectFit: 'contain',
  },
  infoWrapper: {
    marginLeft: 16,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.black,
  },
  description: {
    marginTop: 4,
    marginBottom: 8,
    fontWeight: 400,
    fontSize: 14,
    color: colors.black,
  },
}));
