import { gql, useMutation } from '@apollo/client';

export const useSendWishBagReminderMutation = () => {
  const [sendWishBagReminderEvent, { loading }] = useMutation(
    gql`
      mutation amazdShopifyWishBagReminderMessageSend($args: AmazdShopifyWishBagMessageSendDto!) {
        amazdShopifyWishBagReminderMessageSend(args: $args) {
          amazdId
        }
      }
    `,
  );

  const sendWishBagReminder = async (amazdId: string) => {
    return (
      await sendWishBagReminderEvent({
        variables: {
          args: {
            amazdId,
          },
        },
      })
    ).data.amazdShopifyWishBagReminderMessageSend;
  };

  return {
    sendWishBagReminder,
    loading,
  };
};
