import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
  },
  rootIsMyMessage: {
    justifyContent: 'flex-end',
  },
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 0px',
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  cardWrapperIsMyMessage: {
    flexDirection: 'row',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    border: 'solid',
    borderColor: colors.grey30,
    borderWidth: 1,
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 16,
    padding: 16,
    width: 300,
    cursor: 'pointer',
  },
  cardIsMyMessage: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 0,
  },
  wish2Bag: {
    margin: '0 8px',
  },
  imgWrapper: {
    width: 80,
    minWidth: 80,
    height: 80,
    overflow: 'hidden',
    backgroundColor: colors.grey10,
    borderRadius: 8,
  },
  img: {
    width: 80,
    height: 80,
    overflow: 'hidden',
    objectFit: 'contain',
  },
  infoWrapper: {
    marginLeft: 16,
    marginTop: 2,
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.black,
  },
  price: {
    marginTop: 6,
    fontWeight: 400,
    fontSize: 16,
    color: colors.black,
  },
  options: {
    marginTop: 10,
    fontWeight: 400,
    fontSize: 12,
    color: colors.grey80,
  },
  outOfStock: {
    marginTop: 8,
  },
}));
