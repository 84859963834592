import React, { useEffect, useState } from 'react';

import { Icon } from '@amazd/common/assets/icons';
import { useAuthInfo } from '@amazd/common/hooks/auth';
import { useExpertAvailabilityQuery } from '@amazd/common/hooks/useExpertAvailabilityQuery';
import { colors } from '@amazd/common/static';
import { User } from '@amazd/common/types';
import { getAvailabilityText } from '@amazd/common/utils/availabilities';
import { useTranslation, Trans } from '@amazd/common/utils/i18n';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Link, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import { GraphQLError } from 'graphql';
import ISO6391 from 'iso-639-1';

import {
  ANSWER_SURVEY,
  SUBSCRIBE_TO_WHEN_SURVEY_ANSWERED,
  SURVEY_BY_ID,
} from '../../../web/src/views/InboxView/ChatSidebar/SurveyTab/graphql/survey.graphql';
import { SurveyModel } from '../../../web/src/views/InboxView/ChatSidebar/SurveyTab/survey.types';
import { isDev } from '../../apollo/helpers';
import { useChannelActors, useChannelPeerUser } from '../../hooks/useStreamChannel';
import { AmazdRole } from '../../redux/types';
import { hasXdaysPassed } from '../../utils/date';
import { reportError } from '../../utils/exception-reporter';
import Loader from '../Loader';
import UserAvatar from '../UserAvatar';
import { useStyles } from './styles';
import { MessageBoxProps, ReassignData, SurveyData } from './types';

export function SystemMessageWrapper(props: MessageBoxProps): JSX.Element {
  const classes = useStyles(props);
  const { message } = props;

  return (
    <Grid className={classes.root}>
      <Grid className={classes.messageContainer}>
        <Grid className={classes.mainSection}>{props.children}</Grid>
        <span className={classes.dateTime}>{format(new Date(message.created_at as string), 'MMM d, HH:mm')}</span>
      </Grid>
    </Grid>
  );
}

export function Reassign(props: MessageBoxProps): JSX.Element {
  const { isWidgetScreen, message, ownUser, ownUserType, channel } = props;

  const classes = useStyles(props);
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [shrink, setShrink] = useState(false);
  const { owner } = useChannelActors(channel);

  const data = JSON.parse(message?.customData?.content) as ReassignData;
  const { from, to, description } = data;
  const assignToMe = to.id === ownUserType?.id;
  const isShopper = owner?.id === ownUser?.id;

  useEffect(() => {
    setShrink(isWidgetScreen && isExpanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  if (props.isPreview) {
    return assignToMe ? (
      <span>{t('reassign-system-message-to-expert')}...</span>
    ) : (
      <span>{t('reassign-system-message-to-shopper')}...</span>
    );
  }

  return (
    <>
      <div className={classes.avatarSection}>
        {!shrink && (
          <>
            <UserAvatar size={40} userType={from} />
            <div className={classes.arrow}>{arrowRight}</div>
          </>
        )}
        <UserAvatar size={40} userType={to} />
      </div>
      <div
        className={classes.contentSection}
        style={{
          paddingLeft: shrink ? 8 : 16,
        }}
      >
        <div className={classes.messageText}>
          {assignToMe ? (
            <span>
              {t('reassign-system-message-to-expert')}{' '}
              <span className={classes.boldText}>{`${owner?.first_name} ${owner?.last_name}...`}</span>
            </span>
          ) : (
            <span>
              {t('reassign-system-message-to-shopper')}{' '}
              <span style={{ fontWeight: 'bold' }}>{`${to?.user?.firstName} ${to?.user?.lastName}...`}</span>
            </span>
          )}
        </div>
        {!isShopper && (
          <div className={classes.expandableSection}>
            <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
              <AccordionSummary>
                {isExpanded ? t('system-message-click-to-collapse-link') : t('system-message-click-to-expand-link')}
                {isExpanded ? arrowUp : arrowDown}
              </AccordionSummary>
              <AccordionDetails>{description}</AccordionDetails>
            </Accordion>
          </div>
        )}
      </div>
    </>
  );
}

export function Call(props: MessageBoxProps): JSX.Element {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { message, channel } = props;
  const { peerUser } = useChannelPeerUser(channel);

  return (
    <>
      <div className={classes.iconSection}>{callIcon}</div>
      <div className={classes.contentSection}>
        <div className={classes.messageText}>
          <span>
            {`${t('call-system-message')} `}
            <span style={{ fontWeight: 700 }}>{`${peerUser?.first_name}...`}</span>
          </span>
        </div>
        <div className={classes.expandableSection}>
          <Link
            underline="hover"
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => {
              if (props.onJoinVideoCall) {
                props.onJoinVideoCall(message);
              }
            }}
          >
            <span style={{ marginRight: 2, lineHeight: '15px' }}>{t('call-sm-click-to-join-call')}</span>
            <RightArrowIcon />
          </Link>
        </div>
      </div>
    </>
  );
}

function SystemMessageWithIcon(props: MessageBoxProps & { text?: string }) {
  const classes = useStyles(props);

  return (
    <SystemMessageWrapper {...props}>
      <div className={classes.iconSection}>{smileIcon}</div>
      <div className={classes.contentSection}>
        {props.text ? (
          <div className={classes.messageText}>
            <span className={classes.defaultMessageText}>{props.text}</span>
          </div>
        ) : (
          <Loader size={36} style={{ margin: 'unset' }} />
        )}
      </div>
    </SystemMessageWrapper>
  );
}

export function Greeting(props: MessageBoxProps): JSX.Element {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { channel, message, onScheduleCallClick, onViewExpertProfileClick } = props;

  const { ownUserRole } = useChannelPeerUser(channel);
  const [isExpanded, setIsExpanded] = useState(ownUserRole === AmazdRole.OWNER ? true : false);
  const theme = useTheme();

  const customData = message?.customData?.content as string;
  const data = JSON.parse(customData) as ReassignData;
  const mainColor = theme?.palette?.primary?.main ?? 'black';

  const shopper = data.to;
  const expert = data.from;

  if (props.isPreview) {
    return ownUserRole === AmazdRole.OWNER ? (
      <span>{`${t('greeting-sm-to-shopper', {
        shopperFirstName: shopper.user.firstName,
        shopperLastName: shopper.user.lastName,
        expertFirstName: expert.user.firstName,
        expertLastName: expert.user.lastName,
      })}`}</span>
    ) : (
      <span>{`${t('greeting-sm-to-expert', {
        shopperFirstName: shopper.user.firstName,
        shopperLastName: shopper.user.lastName,
        expertFirstName: expert.user.firstName,
        expertLastName: expert.user.lastName,
      })}`}</span>
    );
  }

  return (
    <>
      <div className={classes.iconSection}>{handIcon}</div>
      <div className={classes.contentSection}>
        <div className={classes.messageText}>
          {ownUserRole === AmazdRole.OWNER ? (
            <Grid>
              <Typography variant="inherit" sx={{ fontWeight: 700, mb: '4px' }}>{`${t('chat-greeting-message-title', {
                firstName: shopper.user.firstName,
                lastName: shopper.user.lastName,
              })} `}</Typography>
              <Typography variant="inherit" sx={{ mb: '16px' }}>
                {t('chat-greeting-message-subtitle')}
              </Typography>
              <Typography variant="inherit" sx={{ mb: '16px' }}>
                {t('chat-greeting-message-let-expert-know', {
                  expertFirstName: expert.user.firstName,
                  expertLastName: expert.user.lastName,
                })
                  .split(' ')
                  .map((word, index) => {
                    if (word === expert.user.firstName || word === expert.user.lastName) {
                      return (
                        <span
                          onClick={onViewExpertProfileClick as any}
                          key={index}
                          style={{ fontWeight: 500, color: mainColor, cursor: 'pointer' }}
                        >
                          {word + ' '}
                        </span>
                      );
                    }
                    return word + ' ';
                  })}
              </Typography>
              <Typography variant="inherit" sx={{ mb: '16px' }}>
                {t('chat-greeting-message-want-a-call', {
                  expertFirstName: expert.user.firstName,
                  expertLastName: expert.user.lastName,
                })}
              </Typography>
              <Grid
                onClick={onScheduleCallClick}
                sx={{
                  borderRadius: '12px',
                  bgcolor: '#F3F4FC',
                  p: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  mb: '30px',
                  cursor: 'pointer',
                }}
              >
                <Icon name="phone-thin-filled" style={{ fill: mainColor, width: '40px', height: '40px' }} />
                <Typography variant="inherit" sx={{ ml: '8px', fontWeight: 500 }}>
                  {t('chat-greeting-message-schedule-call-button')}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <span>
              <span style={{ fontWeight: 700 }}>{`${t('greeting-sm-hi', {
                firstName: expert.user.firstName,
                lastName: expert.user.lastName,
              })} `}</span>
              <span>{`${t('greeting-sm-time-to-amaze')} `}</span>
              <span style={{ fontWeight: 700 }}>{`${shopper.user.firstName} `}</span>
              <span>{t('greeting-sm-with-your-expert-advice')}</span>
            </span>
          )}
        </div>
        {ownUserRole !== AmazdRole.OWNER && (
          <div className={classes.expandableSection}>
            <div className={classes.expandableSection}>
              <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
                <AccordionSummary>
                  {isExpanded ? t('system-message-click-to-collapse-link') : t('system-message-click-to-expand-link')}
                  {isExpanded ? arrowUp : arrowDown}
                </AccordionSummary>
                <AccordionDetails>
                  <span style={{ color: 'black' }}>
                    <span>{t('greeting-sm-about')}</span>
                    <br />
                    <ul>
                      <li>{`${shopper.user.firstName} ${shopper.user.lastName}`}</li>
                      <li>{`${shopper.user.email}`}</li>
                      {!!shopper.user?.spokenLanguages?.length && (
                        <li>
                          {t('greeting-sm-spoken-languages')}:{' '}
                          {`${shopper.user.spokenLanguages
                            .map((code) => `${ISO6391.getName(code)} (${ISO6391.getNativeName(code)})`)
                            .join(', ')}`}
                        </li>
                      )}
                    </ul>
                  </span>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export function Survey(props: MessageBoxProps): JSX.Element | null {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { message, channel } = props;
  const { owner, expert } = useChannelActors(channel);
  const customData = message?.customData?.content as string;
  const data = JSON.parse(customData) as SurveyData;

  const { ownUser, ownUserType } = useAuthInfo();
  const [mostRecentSurvey, setMostRecentSurvey] = useState<string | null>(null);

  const {
    data: surveyData,
    loading: loadingSurvey,
    refetch: refetchSurvey,
    error: errorSurvey,
  } = useQuery(SURVEY_BY_ID, {
    variables: {
      surveyId: data?.surveyId,
    },
  });

  const [answerSurvey, { loading: loadingAnswerSurvey, error: errorAnswer }] = useMutation(ANSWER_SURVEY, {
    refetchQueries: [
      {
        query: SURVEY_BY_ID,
        variables: { surveyId: data?.surveyId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const { error: errorSubscription } = useSubscription(SUBSCRIBE_TO_WHEN_SURVEY_ANSWERED, {
    variables: {
      data: {
        surveyId: mostRecentSurvey,
      },
    },
    shouldResubscribe: true,
    skip: mostRecentSurvey === null,
  });

  useEffect(() => {
    errorSubscription && reportError(errorSubscription, { extraInfo: { amazdId: channel.id } });
    errorAnswer && reportError(errorAnswer, { extraInfo: { amazdId: channel.id } });
    errorSurvey && reportError(errorSurvey, { extraInfo: { amazdId: channel.id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorSurvey, errorAnswer, errorSubscription]);

  useEffect(() => {
    if (
      surveyData &&
      surveyData.survey.score === null &&
      !isSurveyExpired(surveyData.survey) &&
      mostRecentSurvey !== surveyData.survey.id &&
      ownUserType?.isExpert
    ) {
      //   subscribe for experts on unanswered surveys
      setMostRecentSurvey(surveyData.survey.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyData]);

  if (loadingSurvey) {
    //  placeholder message
    return <SystemMessageWithIcon {...props} />;
  }

  isDev() && console.log('surveyData ', surveyData);

  isDev() &&
    console.log(
      'DAYS difference ',
      +differenceInCalendarDays(new Date().getTime(), parseISO(surveyData?.survey?.createdAt)),
    );

  if (ownUser?.id === expert?.id || ownUserType?.isExpert) {
    //    if currentUser = expert of the amazd or any expert looking at the amazd
    //    expert side

    if (surveyData?.survey?.score) {
      // answered survey

      return (
        <SystemMessageWithIcon
          {...props}
          text={t('survey-results-arrived-system-message', {
            name: owner?.first_name,
            score: surveyData.survey.score,
            maxScore: 10,
          })}
        />
      );
    }

    if (surveyData && isSurveyExpired(surveyData?.survey)) {
      // expired survey

      return <SystemMessageWithIcon {...props} text={t('survey-expired-system-message')} />;
    }

    // unanswered survey
    return (
      <SystemMessageWithIcon
        {...props}
        text={t('survey-sent-system-message', {
          name: `${owner?.first_name || ''} ${owner?.last_name || ''}`,
        })}
      />
    );
  }
  //shopper side

  if (surveyData?.survey?.score) {
    //survey answered
    return <SystemMessageWithIcon {...props} text={t('survey-submitted-system-message')} />;
  }

  if (surveyData && isSurveyExpired(surveyData?.survey)) {
    // survey expired
    return null;
  }

  const scores = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleAnswerSurveyClick = (score: number) => {
    if (!loadingAnswerSurvey) {
      answerSurvey({
        variables: { score, surveyId: data?.surveyId },
      }).catch((graphQLError: ErrorResponse | any) => {
        const graphQLErrors = graphQLError.graphQLErrors || [];
        const error: GraphQLError = graphQLErrors[0];
        if (error?.extensions?.status === 403) {
          //rerender in case of an expired survey
          void refetchSurvey({ surveyId: data?.surveyId });
        } else {
          throw graphQLError;
        }
      });
    }
  };

  //unexpired & unanswered survey

  return (
    <>
      <SystemMessageWrapper {...props}>
        <div className={classes.iconSection}>{smileIcon}</div>
        <div className={classes.contentSection}>
          <div>{t('survey-will-recommend-system-message')}</div>
          <div className={classes.scoreHeadingContainer}>
            <span>{t('survey-recommend-not-system-message')}</span>{' '}
            <span>{t('survey-recommend-yes-system-message')}</span>
          </div>
          <div className={classes.scoresContainer}>
            {scores.map((score) => (
              <span
                key={score}
                onClick={() => handleAnswerSurveyClick(score)}
                className={clsx(classes.score, loadingAnswerSurvey && classes.scoreDisabled)}
              >
                {score}
              </span>
            ))}
          </div>
        </div>
      </SystemMessageWrapper>
    </>
  );
}

export function ShopperAwaitingReply(props: MessageBoxProps) {
  const { t, i18n } = useTranslation();
  const { message } = props;
  const theme = useTheme();

  const mainColor = theme?.palette?.primary?.main ?? 'black';

  let shopper: User | undefined;
  let expert: User | undefined;
  try {
    const data = JSON.parse(message?.customData?.content);
    shopper = data.shopper;
    expert = data.expert;
  } catch (error) {
    console.error(error);
  }

  const availability = useExpertAvailabilityQuery(expert?.id);

  const availabilityText = availability
    ? getAvailabilityText(i18n.language, availability.isAvailable, availability.nextAvailability, {
        includeHours: true,
      })
    : { key: '', options: {} as any };

  let titleText = '';

  const knownWhenBeBack = !!(
    availability?.nextAvailability &&
    !availability.isAvailable &&
    availability?.nextAvailability !== -1
  );

  if (knownWhenBeBack) {
    const lowerCaseFirstLetter = (s: string) => {
      return s.charAt(0).toLocaleLowerCase() + s.slice(1);
    };

    titleText = t('chat-shopper-reply-message-will-be-back', {
      expertFirstName: expert?.firstName || 'Expert',
      backAt: lowerCaseFirstLetter(t(availabilityText.key, availabilityText.options)),
    });
  } else {
    titleText = t('chat-shopper-reply-message-reply-soon', {
      expertFirstName: expert?.firstName || 'Expert',
    });
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: '24px' }}>
      <Box
        sx={{
          bgcolor: colors.grey10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: '8px',
          borderRadius: '100%',
          mr: '8px',
        }}
      >
        <Icon name="icon-bell" width="24px" height="24px" color={mainColor} />
      </Box>
      <Box>
        <Typography sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '20px', mb: '2px' }}>{titleText}</Typography>
        <Typography sx={{ fontSize: '14px', lineHeight: '20px', wordBreak: 'break-word' }}>
          {t(
            knownWhenBeBack
              ? 'chat-shopper-reply-message-subtitle-when-be-back'
              : 'chat-shopper-reply-message-subtitle-when-reply-soon',
            {
              expertFirstName: expert?.firstName || 'Expert',
            },
          )}{' '}
          <a href={`mailto:${shopper?.email}`} style={{ color: mainColor, textDecoration: 'none', fontWeight: 500 }}>
            {shopper?.email}
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

export function InstantCallMessages(props: MessageBoxProps) {
  const theme = useTheme();

  const mainColor = theme?.palette?.primary?.main ?? 'black';

  let shopper: User | undefined;
  let expert: User | undefined;

  try {
    const data = JSON.parse(props.message?.customData?.content);
    shopper = data.shopper.userType.user;
    expert = data.expert.userType.user;
  } catch (error) {
    console.error(error);
  }

  const { t } = useTranslation();

  const getWidgetTranslations = () => {
    switch (props.message?.customData?.type) {
      case 'USER_NOT_AVAILABLE_FOR_CALLS':
        return {
          headline: t('user-not-available-for-calls-expert-headline'),
          text: t('user-not-available-for-calls-expert-text'),
        };
      case 'USER_DID_NOT_PICKUP':
        return {
          headline: t('user-did-not-pickup-expert-headline'),
          text: t('user-did-not-pickup-expert-text'),
        };
      default:
        return null;
    }
  };

  const getWebAppTranslations = () => {
    switch (props.message?.customData?.type) {
      case 'USER_NOT_AVAILABLE_FOR_CALLS':
        return {
          headline: t('user-not-available-for-calls-shopper-headline'),
          text: t('user-not-available-for-calls-shopper-text'),
        };
      case 'USER_DID_NOT_PICKUP':
        return {
          headline: t('user-did-not-pickup-shopper-headline'),
          text: t('user-did-not-pickup-shopper-text'),
        };
      default:
        return null;
    }
  };

  const translations = props.isWidgetScreen ? getWidgetTranslations() : getWebAppTranslations();

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: '24px' }}>
      <Box
        sx={{
          bgcolor: colors.grey10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: '8px',
          borderRadius: '100%',
          mr: '8px',
        }}
      >
        <Icon name="icon-bell" width="24px" height="24px" color={mainColor} />
      </Box>
      <Box>
        <Typography sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '20px', mb: '2px' }}>
          {translations?.headline}
        </Typography>
        <Typography sx={{ fontSize: '14px', lineHeight: '20px' }}>
          <Trans
            i18nKey={translations?.text}
            values={{
              expertFirstName: expert?.firstName,
              expertLastName: expert?.lastName,
              shopperFirstName: shopper?.firstName,
              shopperLastName: shopper?.lastName,
            }}
            components={{
              1: <a onClick={props.onScheduleCallClick} style={{ color: mainColor, cursor: 'pointer' }} />,
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
}

export default function SystemMessageBox(props: MessageBoxProps): JSX.Element | null {
  const { message, channel } = props;
  const type = message?.customData?.type;
  const { ownUserRole } = useChannelPeerUser(channel);

  if (message.type === 'deleted') {
    return <></>;
  }

  if (type === 'SURVEY') {
    return <Survey {...props} />;
  }

  let content: JSX.Element | null = null;

  if (type === 'REASSIGN') {
    content = <Reassign {...props} />;
  }
  if (type === 'call') {
    content = <Call {...props} />;
  }
  if (type === 'GREETING') {
    content = <Greeting {...props} />;
  }
  if (type === 'SHOPPER_AWAITING_REPLY') {
    if (ownUserRole === AmazdRole.OWNER) {
      content = <ShopperAwaitingReply {...props} />;
    }
  }
  if (['USER_NOT_AVAILABLE_FOR_CALLS', 'USER_DID_NOT_PICKUP'].includes(type as string)) {
    content = <InstantCallMessages {...props} />;
  }

  if (!content) return null;

  return <SystemMessageWrapper {...props}>{content}</SystemMessageWrapper>;
}

const isSurveyExpired = (survey: SurveyModel) => hasXdaysPassed(30, parseISO(survey.createdAt));

const arrowUp = (
  <svg
    style={{ margin: '0 4px', transform: 'rotate(180deg)' }}
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L5 5L9 1" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const arrowDown = (
  <svg
    style={{ margin: '0 4px' }}
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L5 5L9 1" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

function RightArrowIcon(props: any) {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 12L10 8L6 4" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

const arrowRight = (
  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.28585 2.59254L1.5171 0.606912C0.987763 0.227292 0.250878 0.605622 0.250878 1.25702L0.250878 3.24264V5.22826C0.250878 5.87966 0.987762 6.25799 1.5171 5.87837L4.28585 3.89274C4.73088 3.57358 4.73088 2.9117 4.28585 2.59254Z"
      fill="black"
    />
  </svg>
);

const callIcon = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#F3F4FC" stroke="#F3F4FC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.131 21.869C16.961 20.699 16.079 19.389 15.493 18.06C15.37 17.779 15.442 17.45 15.659 17.233L16.478 16.414C17.149 15.743 17.149 14.794 16.563 14.208L15.39 13.035C14.609 12.254 13.343 12.254 12.562 13.035L11.91 13.686C11.169 14.427 10.86 15.496 11.06 16.556C11.554 19.169 13.072 22.03 15.521 24.479C17.97 26.928 20.831 28.446 23.444 28.94C24.504 29.14 25.573 28.831 26.314 28.09L26.965 27.439C27.746 26.658 27.746 25.392 26.965 24.611L25.792 23.438C25.206 22.852 24.256 22.852 23.671 23.438L22.768 24.342C22.551 24.559 22.222 24.632 21.941 24.508C20.612 23.921 19.301 23.038 18.131 21.869Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const smileIcon = (
  <svg
    style={{ stroke: 'white' }}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#fff" />
    <path
      d="M16.5 17V18M23.5 17V18M23.5 22.688C23.5 22.688 22.187 24 20 24C17.812 24 16.5 22.688 16.5 22.688M11 20C11 15.029 15.029 11 20 11C24.971 11 29 15.029 29 20C29 24.971 24.971 29 20 29C15.029 29 11 24.971 11 20Z"
      stroke="#7E05FE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const handIcon = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#F3F4FC" stroke="#F3F4FC" />
    <g clipPath="url(#clip0_18:2241)">
      <path
        d="M18.8485 21.309L13.2833 15.7438C12.7398 15.2004 12.7398 14.3231 13.2833 13.7796C13.8201 13.2427 14.704 13.2362 15.2475 13.7796L20.8126 19.3448"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5928 17.0536C14.0493 16.5101 13.1654 16.5167 12.6286 17.0536C12.0851 17.597 12.0851 18.4743 12.6286 19.0177L16.8843 23.2735"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5569 15.0894C16.0135 14.546 16.0135 13.6687 16.5569 13.1252C17.0938 12.5884 17.9777 12.5818 18.5211 13.1252L22.1221 16.7262L24.0863 18.6904L24.0797 16.1959C24.0732 15.4037 24.7214 14.7555 25.5136 14.7621C26.1945 14.7621 26.7838 15.2466 26.9213 15.9209L28.0605 21.6694C28.414 23.4896 27.851 25.3752 26.535 26.6912L25.363 27.8632C23.1932 30.0329 19.676 30.0329 17.5063 27.8632L14.2326 24.5895L11.9411 22.298C11.3976 21.7546 11.3976 20.8772 11.9411 20.3338C12.4779 19.7969 13.3618 19.7904 13.9053 20.3338"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_18:2241">
        <rect width="22.2222" height="22.2222" fill="white" transform="translate(4.44444 20) rotate(-45)" />
      </clipPath>
    </defs>
  </svg>
);
