import { Select as BaseSelect } from '@mui/material';
import { styled } from '@mui/system';

const Select = styled(BaseSelect)({
  display: 'flex',
  height: 40,
  '& .MuiSelect-select.MuiInputBase-input': {
    padding: '0 !important',
    paddingLeft: '16px !important',
    paddingRight: '32px !important',
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
  borderRadius: '30px !important',
  backgroundColor: '#F3F4FC',
  '& .MuiSelect-select:focus': {
    backgroundColor: 'unset',
  },
  '& .MuiSelect-icon': {
    marginRight: 5,
  },
});

export default Select;
