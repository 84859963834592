import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { useAuthInfo } from '@amazd/common/hooks/auth';
import { colors } from '@amazd/common/static';
import { Storage } from '@amazd/common/storage';
import { Emoji, NimblePicker } from 'emoji-mart';
import type { Channel as StreamChannel, Message } from 'stream-chat';
import {
  Channel,
  Chat,
  MessageInputFlat,
  MessageList,
  MessageNotificationProps,
  MessageSimple,
  useChannelStateContext,
  useMessageContext,
  useMessageInputContext,
  Window,
} from 'stream-chat-react';

import { useImpersonation } from '../../hooks/useImpersonation';
import { StreamUserExtended, useChannelActors, useChannelPeerUser } from '../../hooks/useStreamChannel';
import { useStreamI18Instance } from '../../hooks/useStreamI18Instance';
import { useStreamStyleOverrides } from '../../hooks/useStreamStyleOverrides';
import { ArrowDownIcon, PlusIcon } from '../../icons';
import { StreamMessage, StreamMessageCustomDataType } from '../../redux/types/chat.types';
import { ThemePreferences } from '../../theme';
import SystemMessageBox from '../ChatSystemMessageBox';
import Loader from '../Loader';
import useStyles from './styles';
import { CustomUserMessageBoxProps } from './types';

export * from './messages';

interface SystemMessageActions {
  onJoinVideoCall?: () => void;
  onScheduleCallClick?: () => void;
  onViewExpertProfileClick?: (target?: EventTarget) => void;
}

interface StreamChatProps {
  channel: StreamChannel;
  CustomUserMessageBox: React.FC;
  MessageInputWrapper: React.FC;
  isWidgetScreen: boolean;
  systemMessageActions: SystemMessageActions;
  unreadCountRef?: MutableRefObject<number>;
  isScrolledUpInChatRef?: MutableRefObject<boolean>;
  widgetMinimized?: boolean;
  themePreferences?: ThemePreferences;
}

const MessageSimpleExtended = (props: {
  isWidgetScreen: boolean;
  systemMessageActions: SystemMessageActions;
  onDropMessageClick: () => void;
  CustomUserMessageBox: React.FC<CustomUserMessageBoxProps>;
}) => {
  const messageContext = useMessageContext();
  const { channel } = useChannelStateContext();
  const { ownUser, ownUserType } = useAuthInfo();

  const { expert } = useChannelActors(channel);
  const { message, isMyMessage } = messageContext;
  const { isOwnUserMember } = useChannelPeerUser(channel);
  const isCustomMessage = Boolean(message.customData);
  const { onJoinVideoCall, onScheduleCallClick, onViewExpertProfileClick } = props.systemMessageActions;

  const handleIsMyMessage = () => {
    if (isOwnUserMember) {
      return isMyMessage();
    } else {
      return message?.user?.id === ownUser?.id || message?.user?.id === (expert as StreamUserExtended).id;
    }
  };

  if (!isCustomMessage) return <MessageSimple isMyMessage={handleIsMyMessage} />;
  else if (isCustomMessage) {
    const customMessageType = (message as StreamMessage).customData?.type as StreamMessageCustomDataType;
    const { CustomUserMessageBox } = props;
    if (
      CustomUserMessageBox &&
      (
        ['SHOPIFY_CHECKOUT', 'SHOPIFY_PRODUCT', 'SHOPIFY_WISH_BAG_REMINDER'] as Array<StreamMessageCustomDataType>
      ).includes(customMessageType)
    )
      return <CustomUserMessageBox message={message} isMyMessage={handleIsMyMessage()} />;
  }

  return (
    <SystemMessageBox
      message={message}
      ownUser={ownUser}
      ownUserType={ownUserType}
      channel={channel}
      onJoinVideoCall={onJoinVideoCall}
      onDropMessageClick={props.onDropMessageClick}
      onScheduleCallClick={onScheduleCallClick}
      onViewExpertProfileClick={onViewExpertProfileClick}
      isWidgetScreen={props.isWidgetScreen}
    />
  );
};

/**
 * MessageNotification extends (in a way) Stream's native `MessageNotification` component and we just
 * add two extra props to it to get access to some of Stream's internal state (primarily showNotification state)
 * and show the unread count as well (Stream's native `MessageNotification` doesn't show count)
 */
const MessageNotification: React.FC<
  MessageNotificationProps & {
    children?: React.ReactNode;
    unreadCountRef?: MutableRefObject<number>;
    onChange: (val: boolean) => void;
  }
> = (props) => {
  const { unreadCountRef, onChange, children, onClick, showNotification = true } = props;
  useEffect(() => {
    onChange(showNotification);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);

  const unreadCount = unreadCountRef?.current;

  if (!showNotification) return null;

  if (!unreadCount) {
    return (
      <button
        aria-live="polite"
        className="str-chat__message-notification"
        data-testid="message-notification"
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      aria-live="polite"
      className="str-chat__message-notification"
      data-testid="message-notification"
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        boxShadow: '0px 0px 25px rgba(28, 28, 33, 0.1)',
        borderRadius: '100px',
        fontFamily: 'FilsonPro',
        fontSize: '12px',
        fontWeight: 500,
        color: colors.black,
        padding: '8px 16px',
      }}
    >
      <span>
        {`${unreadCount > 99 ? '99+' : unreadCount}`} new message{unreadCount > 1 && 's'}
      </span>
      <ArrowDownIcon style={{ width: 10, height: 5, color: colors.black, marginLeft: '10px' }} />
    </button>
  );
};

/*
  As useMessageInputContext initializes only within the Input component, 
  this seems to be the only way to obtain input element ref.
*/
const CustomInput = (props: {
  setInputRef: (inputRef: MutableRefObject<HTMLTextAreaElement | undefined | null>) => void;
}) => {
  const { textareaRef, text, setText } = useMessageInputContext();

  const { channel } = useChannelStateContext();
  const storageUnsentMessage = new Storage(`draftmsg-${channel.id as string}`);

  useEffect(() => {
    props.setInputRef(textareaRef);
    const unsentMessage = storageUnsentMessage.getItem();
    setText(unsentMessage || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const savedMessage = storageUnsentMessage.getItem();
    // below if check it's needed to work widget properly
    if (!(savedMessage && !text) || text || channel.lastMessage()?.text === savedMessage)
      storageUnsentMessage.setItem(text);
    // eslint-disable-next-line
  }, [text]);

  return <MessageInputFlat />;
};

function StreamChat({
  channel,
  systemMessageActions,
  unreadCountRef,
  isScrolledUpInChatRef,
  widgetMinimized,
  isWidgetScreen,
  CustomUserMessageBox,
  MessageInputWrapper,
  themePreferences,
}: StreamChatProps): JSX.Element {
  const classes = useStyles();

  const i18Instance = useStreamI18Instance();
  const styleOverrides = useStreamStyleOverrides(themePreferences);

  const { owner, expert } = useChannelActors(channel);
  const { isOwnUserMember } = useChannelPeerUser(channel);

  const [inputRef, setInputRef] = useState<MutableRefObject<HTMLTextAreaElement | undefined | null>>();

  const { isImpersonating } = useImpersonation();

  // Using `widgetMinimized` directly doesn't work due to closure issues (Stream's internal caching)
  // so a ref is necessary here
  const widgetMinimizedRef = useRef(widgetMinimized);

  const customClasses = {
    chat: classes.chat,
    messageList: classes.messageList,
  };

  const allowedActions = isOwnUserMember ? ['edit', 'delete', 'quote', 'react', 'reply'] : [];
  const client = channel?.getClient();
  const isHealthy = client.wsConnection?.isHealthy;

  const markRead = () => {
    // do not send Manager user read events,
    // send read events only for channel members,
    // Also, don't call mark read if widget is minimized
    if (isImpersonating) {
      return;
    }
    if (isOwnUserMember && !widgetMinimizedRef.current) {
      channel.markRead();
    }
  };

  const handleMarkReadRequest = () => {
    if (isScrolledUpInChatRef == null) {
      markRead();
    } else {
      // `setTimeout` is used here because we want `onShowNotificationChange` from `MessageNotification`
      //  to be called first if we're tracking scroll position - GetStream's implementation calls `handleMarkReadRequest` first
      setTimeout(() => {
        // Do not send read events if user is scrolled up
        // in chat. This allows us to show unread message count
        if (!isScrolledUpInChatRef.current) {
          markRead();
        }
      }, 1000);
    }
  };

  const handleSendMessageRequest = (_channelId: string, messageData: Message) => {
    return channel.sendMessage(messageData);
  };

  const handleDropMessageClick = () => {
    inputRef?.current?.focus();
  };

  const onShowNotificationChange = (showNotification: boolean) => {
    if (isScrolledUpInChatRef != null) {
      isScrolledUpInChatRef.current = showNotification;
      if (!isScrolledUpInChatRef.current) {
        markRead();
      }
    }
  };

  useEffect(() => {
    widgetMinimizedRef.current = widgetMinimized;
    handleMarkReadRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetMinimized]);

  if (!client || !isHealthy || !channel || !owner || !expert) {
    return <Loader size={100} />;
  }

  return (
    <Chat client={client} customClasses={customClasses} customStyles={styleOverrides} i18nInstance={i18Instance}>
      <Channel
        Emoji={Emoji}
        EmojiPicker={NimblePicker}
        channel={channel}
        doMarkReadRequest={handleMarkReadRequest}
        doSendMessageRequest={handleSendMessageRequest}
        maxNumberOfFiles={10}
        FileUploadIcon={() => <PlusIcon />}
        Input={() => <CustomInput setInputRef={setInputRef} />}
        MessageNotification={(props) => (
          // Ref is used for unread count here to avoid closure issues
          <MessageNotification {...props} unreadCountRef={unreadCountRef} onChange={onShowNotificationChange} />
        )}
      >
        <Window>
          <MessageList
            scrolledUpThreshold={10}
            onlySenderCanEdit={true}
            messageActions={allowedActions}
            Message={() => (
              <MessageSimpleExtended
                isWidgetScreen={isWidgetScreen}
                systemMessageActions={systemMessageActions}
                onDropMessageClick={handleDropMessageClick}
                CustomUserMessageBox={CustomUserMessageBox}
              />
            )}
          />
          <MessageInputWrapper />
        </Window>
      </Channel>
    </Chat>
  );
}

const MemoizedStreamChat = React.memo(StreamChat, (prevProps, nextProps) => {
  return (
    prevProps.channel?.id === nextProps.channel?.id &&
    prevProps.widgetMinimized === nextProps.widgetMinimized &&
    prevProps.unreadCountRef === nextProps.unreadCountRef &&
    prevProps.isScrolledUpInChatRef === nextProps.isScrolledUpInChatRef
  );
});

export default MemoizedStreamChat;
