interface IProduct {
  availableForSale?: boolean;
  publishedOnCurrentPublication?: boolean;
  inventoryQuantity?: number;
  totalInventory?: number;
  price?: number | string;
  priceRangeV2?: {
    maxVariantPrice?: {
      amount?: number;
    };
    minVariantPrice?: {
      amount?: number;
    };
  };
}

export function getProductInfo(
  product?: IProduct | null,
  variant?: IProduct | null,
): {
  isOutOfStock: boolean;
  isUnavailable: boolean;
  price?: number;
} {
  let isOutOfStock = false;
  if (variant) {
    if (typeof variant?.availableForSale === 'boolean') {
      isOutOfStock = !variant.availableForSale;
    } else if (typeof variant?.inventoryQuantity === 'number') {
      isOutOfStock = Number(variant.inventoryQuantity) <= 0;
    } else {
      isOutOfStock = !product?.totalInventory;
    }
  } else {
    if (typeof product?.availableForSale === 'boolean') {
      isOutOfStock = !product.availableForSale;
    } else {
      isOutOfStock = !product?.totalInventory;
    }
  }

  const price = variant
    ? variant?.price
    : product?.priceRangeV2?.maxVariantPrice?.amount ?? product?.priceRangeV2?.minVariantPrice?.amount;

  const isUnavailable = isOutOfStock || product?.publishedOnCurrentPublication === false;

  return { isOutOfStock, isUnavailable, price: price != undefined ? Number(price) : undefined };
}

export const ProductShortFragment = `
  id
  title
  sku
  stockStatus
  featuredImage {
    id
    url
  }
  thumbnailImage {
    id
    url
  }
  openUrl
  availableForSale
  publishedOnCurrentPublication
  hasOnlyDefaultVariant
  totalInventory
  tracksInventory
  createdAt
  priceRangeV2 {
    minVariantPrice {
      currencyCode
      amount
    }
    maxVariantPrice {
      currencyCode
      amount
    }
  }
  categories {
    items {
      id
      title
    }
  }
`;

export const ProductVariantFragment = `
  id
  title
  sku
  stockStatus
  openUrl
  availableForSale
  image {
    id
    url
  }
  price
  inventoryQuantity
  selectedOptions {
    name
    value
  }
`;

export const ProductFragment = `
  ${ProductShortFragment}
  options {
    id
    name
    position
    values
  }
  variants {
    ${ProductVariantFragment}
  }
`;
