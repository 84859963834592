import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react';

import { useShopDetailsByAmazdId } from '@amazd/common/hooks/useShopDetails';
import { useWishbagQuery } from '@amazd/common/hooks/useWishbagQuery';
import { ShopProduct, ShopProductVariant } from '@amazd/common/types/shop.types';
import { AmazdWishBagData, WishbagDiscount } from '@amazd/common/types/wishbag.types';
import type { Channel } from 'stream-chat';

import { useApplyDiscountMutation } from './useApplyDiscountMutation';
import { useRemoveDiscountMutation } from './useRemoveDiscountMutation';
import { useSendWishBagReminderMutation } from './useSendWishBagReminderMutation';
import { useUpdateWishBagMutation } from './useUpdateWishBagMutation';

export interface LineItem {
  product: ShopProduct;
  variant?: ShopProductVariant;
  quantity: number;
  variantId?: string;
}

type ShopifyCartContextType = {
  loading?: boolean;
  onSendWishBagReminder: () => void;
  loadingSend?: boolean;
  discountTotal: number;
  discounts: WishbagDiscount[];
  applyDiscountToWishBag: (code: string) => Promise<boolean>;
  removeDiscountFromWishBag: (code: string) => void;
  loadingApplyDiscount?: boolean;
  loadingRemoveDiscount?: boolean;
  loadingSave?: boolean;
  lineItems: Array<LineItem>;
  setLineItems: (lineItems: Array<LineItem>) => void;
  subTotal: number;
  itemsCount: number;
  addLineItem: (item: Partial<LineItem>) => void;
  incrementQuantity: (item: LineItem) => void;
  decrementQuantity: (item: LineItem) => void;
  removeLineItem: (item: LineItem) => void;
  getLineItem: (query: { product: ShopProduct; variant?: ShopProductVariant }) => {
    itemIndex: number;
    lineItem?: LineItem;
  };
  channel?: Channel;
};

const ShopifyCartContext = createContext<ShopifyCartContextType>({
  loading: false,
  onSendWishBagReminder: () => null,
  loadingSend: false,
  discountTotal: 0,
  discounts: [],
  applyDiscountToWishBag: async () => false,
  loadingApplyDiscount: false,
  removeDiscountFromWishBag: () => null,
  loadingRemoveDiscount: false,
  loadingSave: false,
  lineItems: [],
  setLineItems: () => null,
  subTotal: 0,
  itemsCount: 0,
  addLineItem: () => null,
  incrementQuantity: () => null,
  decrementQuantity: () => null,
  removeLineItem: () => null,
  getLineItem: () => ({ itemIndex: -1 }),
});

interface ShopifyCartProviderProps {
  channel?: Channel;
  defaultValue?: ShopifyCartContextType;
  children?: ReactElement<any, any>;
}
export const ShopifyCartProvider = (props: ShopifyCartProviderProps) => {
  const [lineItems, setLineItems] = useState<Array<LineItem>>([]);
  const [wishbagData, _setWishbagData] = useState<AmazdWishBagData | null>(null);
  const { details } = useShopDetailsByAmazdId(props.channel?.id as string);

  // get wishbag by amazdId
  const { loading, wishbag } = useWishbagQuery(props.channel?.id as string, details?.shopDomain);

  const setWishbagData = (wishbagData: AmazdWishBagData | undefined) => {
    _setWishbagData(wishbagData || null);
    setLineItems(JSON.parse(JSON.stringify(wishbagData?.lineItems || [])));
  };

  useEffect(() => {
    setWishbagData(wishbag?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishbag, props.channel?.id]);

  const { updateWishBag, loading: loadingSave } = useUpdateWishBagMutation();
  const { sendWishBagReminder, loading: loadingSend } = useSendWishBagReminderMutation();
  const { applyDiscount, loading: loadingApplyDiscount } = useApplyDiscountMutation();
  const { removeDiscount, loading: loadingRemoveDiscount } = useRemoveDiscountMutation();

  const onSendWishBagReminder = async () => {
    if (props.channel?.id) {
      await sendWishBagReminder(props.channel?.id as string);
    }
  };

  const onSaveWishBag = async (lineItems: Array<LineItem>) => {
    // push changes
    if (props.channel?.id) {
      const response = await updateWishBag(props.channel.id, { lineItems });
      setWishbagData(response.data as AmazdWishBagData);
    }
  };

  const applyDiscountToWishBag = async (discountCode: string) => {
    let isApplied = false;
    if (props.channel?.id) {
      const response = await applyDiscount(props.channel.id as string, discountCode);
      const data = response.data as AmazdWishBagData;
      setWishbagData(data);

      if (data?.discounts?.find((discount) => discount.code === discountCode)) {
        isApplied = true;
      }
    }

    return isApplied;
  };

  const removeDiscountFromWishBag = async (discountCode: string) => {
    if (props.channel?.id) {
      const response = await removeDiscount(props.channel?.id as string, discountCode);
      const wishbagData = response.data as AmazdWishBagData;
      setWishbagData(wishbagData);
    }
  };

  const getLineItem = ({ product, variant }: { product: ShopProduct; variant?: ShopProductVariant }) => {
    const itemIndex = lineItems.findIndex((lineItem) =>
      variant ? lineItem.variant?.id === variant.id : lineItem.product.id === product.id,
    );
    return {
      itemIndex,
      lineItem: lineItems[itemIndex],
    };
  };

  const addLineItem = (item: Partial<LineItem>) => {
    const product = item?.product;
    if (!product) return;

    const newLineItems = [...lineItems];
    // check if productVariant exist increment quantity
    const { itemIndex } = getLineItem({ ...item, product });
    if (itemIndex !== -1) {
      newLineItems[itemIndex].quantity += 1;
    } else {
      // otherwise append
      newLineItems.push({
        product: product,
        variant: item.variant,
        quantity: item.quantity ?? 1,
        variantId: item.variant?.id,
      });
    }
    setLineItems(newLineItems);
    onSaveWishBag(newLineItems);
  };

  const incrementQuantity = (item: LineItem) => {
    const newLineItems = [...lineItems];
    // check if productVariant exist increment quantity
    const { itemIndex } = getLineItem(item);
    if (itemIndex !== -1) newLineItems[itemIndex].quantity += 1;

    setLineItems(newLineItems);
    onSaveWishBag(newLineItems);
  };

  const decrementQuantity = (item: LineItem) => {
    const newLineItems = [...lineItems];
    // check if productVariant exist increment quantity
    const { itemIndex } = getLineItem(item);
    if (itemIndex !== -1) {
      newLineItems[itemIndex].quantity -= 1;
      if (newLineItems[itemIndex].quantity <= 0) newLineItems.splice(itemIndex, 1);
    }
    setLineItems(newLineItems);
    onSaveWishBag(newLineItems);
  };

  const removeLineItem = (item: LineItem) => {
    const { itemIndex } = getLineItem(item);
    if (itemIndex !== -1) {
      const newLineItems = lineItems.filter((_, index) => index !== itemIndex);
      setLineItems(newLineItems);
      onSaveWishBag(newLineItems);
    }
  };

  return (
    <ShopifyCartContext.Provider
      value={{
        loading,
        onSendWishBagReminder,
        loadingSend,
        loadingApplyDiscount,
        applyDiscountToWishBag,
        removeDiscountFromWishBag,
        loadingRemoveDiscount,
        loadingSave,
        lineItems,
        setLineItems,
        subTotal: wishbagData?.subtotal || 0,
        discountTotal: wishbagData?.discountTotal || 0,
        itemsCount: wishbagData?.itemsCount || 0,
        discounts: wishbagData?.discounts || [],
        addLineItem,
        incrementQuantity,
        decrementQuantity,
        removeLineItem,
        getLineItem,
        channel: props.channel,
      }}
      {...props}
    />
  );
};

export const useShopifyCartContext = () => useContext(ShopifyCartContext);
