import { MessageTimestamp } from 'stream-chat-react';

import { CustomUserMessageBoxProps } from '../../types';
import useStyles from './styles';

export const MessageFooter = ({ message, isMyMessage }: CustomUserMessageBoxProps) => {
  const classes = useStyles();

  return (
    <div className={`${classes.date} ${isMyMessage && classes.dateIsMyMessage}`}>
      {!isMyMessage && <span className={classes.name}>{message.user?.name}</span>}
      <MessageTimestamp calendar message={message} />
    </div>
  );
};
