import { WishbagFragment } from '@amazd/common/hooks/useWishbagQuery';
import { gql, useMutation } from '@apollo/client';

export const useRemoveDiscountMutation = () => {
  const [removeDiscountEvent, { loading }] = useMutation(
    gql`
    mutation amazdWishbagDiscountRemove($args: AmazdWishBagDiscountRemoveArgs!) {
      amazdWishbagDiscountRemove(args: $args) {
        ${WishbagFragment}        
      }
    }
    `,
  );

  const removeDiscount = async (amazdId: string, discountCode: string) => {
    return (
      await removeDiscountEvent({
        variables: {
          args: {
            amazdId,
            discountCode,
          },
        },
      })
    ).data.amazdWishbagDiscountRemove;
  };

  return {
    removeDiscount,
    loading,
  };
};
