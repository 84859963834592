import React from 'react';

import { useTranslation } from '@amazd/common/utils/i18n';
import { Tooltip } from '@mui/material';

import useStyles from './styles';

export const ProductUnavailableLabel = ({ type = 'out-of-stock' }: { type?: 'out-of-stock' | 'not-published' }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  let label = t('product-catalogue-out-of-stock');
  if (type === 'not-published') {
    label = t('product-catalogue-not-published');
  }

  let tooltipTitle = '';
  if (type === 'not-published') {
    tooltipTitle = t('product-catalogue-not-published-tooltip');
  }

  return (
    <Tooltip title={tooltipTitle} arrow>
      <div className={classes.root}>
        <div className={classes.outOfStockWrapper}>
          <div className={classes.outOfStock}>{label}</div>
        </div>
      </div>
    </Tooltip>
  );
};
