import { colors } from '@amazd/common/static';
import { format, isToday, isTomorrow } from 'date-fns';
import { de, es, fr, nl } from 'date-fns/locale';


export const returnLocale = (language: string) => {
  switch (language) {
    case 'de_formal':
    case 'de':
      return { locale: de };
    case 'fr_formal':
    case 'fr':
      return { locale: fr };
    case 'es_formal':
    case 'es':
      return { locale: es };
    case 'nl_formal':
    case 'nl':
      return { locale: nl };
    default:
      return {};
  }
};

interface AvailabilityTextOptions {
  includeHours?: boolean;
}

export const getAvailabilityText = (
  language: string,
  isAvailable: boolean,
  availability: number,
  options?: AvailabilityTextOptions,
) => {
  const formatOptions = returnLocale(language);

  if (isAvailable) {
    return { key: 'expert-selection-list-available-true' };
  }
  if (!availability || availability === -1) {
    return { key: '', options: { date: '' } };
  }
  if (isToday(availability)) {
    return {
      key: 'expert-selection-list-back-soon',
      options: { date: format(availability, 'HH:mm', formatOptions) },
    };
  }
  if (isTomorrow(availability)) {
    return {
      key: 'expert-selection-list-back-tomorrow',
      options: { date: format(availability, 'HH:mm', formatOptions) },
    };
  }

  let defaultDateString = formatOptions?.locale === de ? 'eee MMM d' : 'eee, MMM d';

  if (options?.includeHours) {
    defaultDateString = `${defaultDateString} HH:mm`;
  }

  return {
    key: 'expert-selection-list-back-on',
    options: { date: format(availability, defaultDateString, formatOptions) },
  };
};

export const getAvailabilityDotColor = (isAvailable: boolean, availability: number) => {
  if (isAvailable) {
    return colors.green100;
  }
  if (isToday(availability)) {
    return 'orange';
  }

  return '';
};
