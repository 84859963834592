import { useEffect, useState } from 'react';

import { getClient } from '@amazd/common/apollo';
import { gql } from '@apollo/client';

export type ExpertAvailability = {
  isAvailable: boolean;
  nextAvailability: number;
};

export const getExpertsAvailabilityQuery = gql`
  query getExpertsAvailability($args: CompanyExpertDto!) {
    companyExpert(args: $args) {
      isAvailable
      nextAvailability
    }
  }
`;

export function useExpertAvailabilityQuery(expertId?: string | null) {
  const [availability, setAvailability] = useState<ExpertAvailability | null>(null);

  const fetchAvailability = async () => {
    const res = await getClient().query({
      query: getExpertsAvailabilityQuery,
      fetchPolicy: 'no-cache',
      variables: {
        args: {
          userId: expertId,
        },
      },
    });

    if (res.errors) return;

    setAvailability(res.data.companyExpert);
  };

  useEffect(() => {
    if (expertId) {
      fetchAvailability();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertId]);

  return availability;
}
