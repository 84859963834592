import { useEffect, useMemo, useState } from 'react';

import { useAuthInfo } from '@amazd/common/hooks/auth';
import { gql, useLazyQuery } from '@apollo/client';
import { StreamChat } from 'stream-chat';

import { isDev } from '../apollo/helpers';
import { useAppConfig } from '../utils/env';
import { useImpersonation } from './useImpersonation';

const streamUserTokenQuery = gql`
  query {
    streamUserToken {
      userToken
    }
  }
`;

export const useStreamClient = (): { client: StreamChat; connected: boolean } => {
  const { env } = useAppConfig();
  const { ownUser } = useAuthInfo();
  const client = useMemo(() => new StreamChat(env.STREAM_API_KEY as string), [env.STREAM_API_KEY]);
  const [connected, setConnected] = useState<boolean>(client.wsConnection?.isHealthy || false);
  const [fetch] = useLazyQuery(streamUserTokenQuery);
  const { isImpersonating } = useImpersonation();

  const connect = async () => {
    setConnected(false);
    const { data } = await fetch();

    const userToken = data.streamUserToken.userToken;
    if (!userToken) {
      throw new Error(`Couldn't get stream token for current user`);
    }

    await client.connectUser({ id: ownUser?.id as string, invisible: isImpersonating }, userToken);
    setConnected(true);
    console.log('Stream Connected!');
  };

  const disconnect = async () => {
    await client.disconnectUser();
    setConnected(false);
  };

  useEffect(() => {
    if (ownUser && !client.user) {
      console.log('Stream Connecting...', ownUser.id);
      connect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownUser]);

  useEffect(() => {
    return () => {
      if (!isDev()) {
        console.log('Stream Disconnecting...');
        disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { client, connected };
};
