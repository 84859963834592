import React from 'react';

import { Icon } from '@amazd/common/assets/icons';
import WishBagIcon from '@amazd/common/icons/WishBagIcon';
import { useTranslation } from '@amazd/common/utils/i18n';
import { Tooltip } from '@mui/material';
import { useShopifyCartContext } from 'components/Shopify/wishBag/hooks/useCart';
import { colors } from 'styles/theme';
import { ChatSidebarTabKey, useChatSidebarContext } from 'views/InboxView/ChatSidebar/context/sidebarContext';

import useStyles from './styles';

export interface Wish2BagButtonProps {
  product: any;
  variant: any;
  tooltipPlacement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
}

export const Wish2BagButton = ({ product, variant, tooltipPlacement = 'top' }: Wish2BagButtonProps) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { addLineItem, removeLineItem, getLineItem } = useShopifyCartContext();

  const { setShowSideBar, setSelectedTab } = useChatSidebarContext();

  const { lineItem } = getLineItem({ product, variant });
  const isInWishBag = !!lineItem;

  const onClickWishIcon = () => {
    if (isInWishBag) removeLineItem(lineItem);
    else {
      setSelectedTab(ChatSidebarTabKey.wishBag);
      setShowSideBar(true);
      addLineItem({ product, variant });
    }
  };

  return (
    <div className={classes.wishBagWrapper} onClick={onClickWishIcon}>
      <Tooltip
        title={
          isInWishBag
            ? t('stream-shopify-product-message-wish-tooltip-remove')
            : t('stream-shopify-product-message-wish-tooltip-add')
        }
        placement={tooltipPlacement}
      >
        <div className={classes.wishBag}>
          {isInWishBag ? (
            <Icon name={'solid-wish-bag-on'} color={colors.purple100} size={24} />
          ) : (
            <WishBagIcon color={colors.purple100} style={{ fontSize: 24 }} />
          )}
        </div>
      </Tooltip>
    </div>
  );
};
