import { useEffect, useReducer, useState } from 'react';

import { useAuthInfo } from '@amazd/common/hooks/auth';
import type { Channel, Event, UserResponse } from 'stream-chat';
import { throttle } from 'throttle-debounce';

import { AmazdRole } from '../redux/types';
import { reportError } from '../utils';
import { useStreamClient } from './useStreamClient';

export type StreamUserExtended = UserResponse & {
  image: string;
  first_name: string;
  last_name: string;
};

export const useStreamChannel = (
  channelId?: string,
  options?: {
    onUserTyping?: (event: Event) => void;
    onNewMessage?: (event: Event) => void;
  },
): { channel: Channel | undefined; loading: boolean } => {
  const { client, connected } = useStreamClient();
  const [channel, setChannel] = useState<Channel>();
  const [loading, setLoading] = useState<boolean>(false);
  const [, forceRerender] = useReducer((x) => x + 1, 0);

  const loadChannel = async () => {
    if (connected && channelId && !loading) {
      setLoading(true);

      const options = {
        state: true,
        watch: true,
        presence: true,
      };

      try {
        const channel = client.channel('messaging', channelId);
        await channel.watch(options);

        setChannel(channel);
      } catch (error: any) {
        reportError(error, { extraInfo: { channelId } });
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    loadChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, connected, channelId]);

  useEffect(() => {
    const onUserTyping = throttle(1000, (event: any) => {
      options?.onUserTyping?.(event);
    });

    const handelEvents = async (event: Event) => {
      switch (event.type) {
        case 'channel.updated':
        case 'user.presence.changed':
        case 'user.updated':
          forceRerender();
          break;
        case 'connection.recovered':
          loadChannel();
          break;
        case 'typing.start':
          onUserTyping(event);
          break;
        case 'typing.stop':
          onUserTyping(event);
          break;
        case 'message.new':
          options?.onNewMessage?.(event);
          break;
        default:
          break;
      }
    };

    if (client && connected) {
      client.on(handelEvents);
    }

    return () => {
      client.off(handelEvents);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, connected]);

  return { channel, loading };
};

export const useChannelActors = (channel?: Channel) => {
  if (!channel) return {};

  const ownerId = channel.data?.owner_user_id as string;
  const expertId = channel.data?.expert_user_id as string;

  const ownerMember = channel.state.members[ownerId];
  const expertMember = channel.state.members[expertId];

  const owner = ownerMember?.user as StreamUserExtended;
  const expert = expertMember?.user as StreamUserExtended;

  return {
    expert,
    owner,
  };
};

export const useChannelPeerUser = (channel?: Channel) => {
  const { owner, expert } = useChannelActors(channel);
  const { ownUser } = useAuthInfo();

  if (!ownUser) {
    throw new Error('OwnUser should be available under the current hook context');
  }

  if (!channel || !owner || !expert) return {};

  const ownUserRole =
    ownUser.id === owner.id ? AmazdRole.OWNER : ownUser.id === expert.id ? AmazdRole.EXPERT : AmazdRole.OTHER;

  const isOwnUserMember = ownUserRole !== AmazdRole.OTHER;

  const peerUser = ownUser?.id === owner.id ? expert : owner;

  const peerUserCompanyId = peerUser?.teams && peerUser?.teams.length > 0 ? peerUser?.teams[0] : undefined;

  return {
    peerUser,
    ownUserRole,
    isOwnUserMember,
    peerUserCompanyId,
  };
};
