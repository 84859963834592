import { CustomTheme } from '@amazd/common/theme';
import { makeStyles } from '@mui/styles';

import { colors } from '../../static';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 5,
    '& ul': {
      marginBlockStart: '8px',
      paddingInlineStart: '20px',
      lineHeight: '22px',
      marginBottom: 0,
      marginTop: 4,
    },
  },
  messageContainer: {
    maxWidth: '100%',
    width: 392,
    background:
      'linear-gradient(0deg, #E7E9F9, #E7E9F9), linear-gradient(0deg, rgba(219, 220, 227, 0.7), rgba(219, 220, 227, 0.7)), #FFFFFF',
    borderRadius: 15,
    padding: '8px 16px 8px 8px',
    position: 'relative',
  },
  mainSection: {
    display: 'flex',
  },
  avatarSection: {
    display: 'flex',
    height: 40,
  },
  iconSection: {
    display: 'flex',
    height: 40,
    '& svg': {
      stroke: theme.palette.primary.main,
    },
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 6,
    paddingRight: 5,
  },
  messageText: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 34,
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: 8,
    overflowWrap: 'anywhere',
  },
  defaultMessageText: {
    wordSpacing: '.2px',
    paddingRight: 2,
  },
  boldText: {
    fontWeight: 'bold',
  },
  expandableSection: {
    color: theme.palette.primary.main,
    '& svg': {
      stroke: theme.palette.primary.main,
    },
    '& .MuiAccordion-root': {
      display: 'flex',
      flexDirection: 'column-reverse',
      background: 'none',
      boxShadow: 'none',
      color: theme.palette.primary.main,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiCollapse-container': {},
    '& .MuiAccordionSummary-root': {
      minHeight: 16,
      padding: 0,
    },
    '& .MuiAccordionDetails-root': {
      marginTop: 8,
      marginBottom: 8,
      padding: 0,
      color: colors.amazdGrey3,
      fontSize: '14px',
      wordBreak: 'break-word',
    },
  },
  contentSection: {
    padding: '0px 8px',
    paddingRight: 0,
    minHeight: 58,
  },
  dateTime: {
    position: 'absolute',
    bottom: 10,
    right: 16,
    fontSize: 10,
    lineHeight: '16px',
    color: colors.amazdGrey3,
    opacity: 0.8,
  },
  score: {
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: 12,
    color: colors.amazdPurple,
    background: colors.grey10,
    borderRadius: 4,
    marginRight: 3.4,
    userSelect: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 18.6,
    width: 18.6,
    '@supports (-moz-appearance:none)': {
      // target firefox media query
      height: 17.7,
      width: 17.7,
    },
  },
  scoreDisabled: {
    background: '#E0E0E0',
    color: 'rgba(0, 0, 0, 0.26) !important',
  },
  scoresContainer: {
    marginBottom: 30,
  },
  scoreHeadingContainer: {
    color: colors.grey70,
    fontWeight: 400,
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 8,
  },
}));
