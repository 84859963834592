import { useEffect, useState } from 'react';

import { getClient } from '@amazd/common/apollo';
import { AmazdWishBagData } from '@amazd/common/types/wishbag.types';
import { ProductFragment, ProductVariantFragment } from '@amazd/common/utils/product';
import { gql } from '@apollo/client';

export const WishbagFragment = `
    amazdId
    data {
      discountTotal
      subtotal
      itemsCount
      discounts {
        code
        isFreeShipping
      }
      lineItems {
        quantity
        variantId
        product {
          ${ProductFragment}
        }
        variant {
          ${ProductVariantFragment}
        }
      }
    }
`;

export const useWishbagQuery = (amazdId: string, shopDomain: string | undefined) => {
  const [wishbag, setWishbag] = useState<{ data: AmazdWishBagData; amazdId: string } | undefined>();
  const [loading, setLoading] = useState(false);

  const getWishbag = async () => {
    if (!amazdId || !shopDomain) return;

    setLoading(true);

    const result = await getClient()
      .query({
        query: gql`
            query amazdWishbag($amazdId: String!) {
              amazdWishbag(amazdId: $amazdId) {
                ${WishbagFragment}
              }
            }
          `,
        variables: {
          amazdId,
        },
        fetchPolicy: 'no-cache',
      })
      .catch(console.error);

    setWishbag(result?.data?.amazdWishbag);

    setLoading(false);
  };

  useEffect(() => {
    getWishbag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amazdId, shopDomain]);

  return {
    refetch: getWishbag,
    loading,
    wishbag,
  };
};
