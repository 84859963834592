import React from 'react';

import type { CustomUserMessageBoxProps } from '@amazd/common/components/StreamChat/types';
import { StreamMessage } from '@amazd/common/redux/types/chat.types';

import { ShopifyProductMessageBox } from './shopifyProductMessageBox';
import { ShopifyWishBagReminderMessageBox } from './shopifyWishBagReminderMessageBox';

export function CustomUserMessageBox(props: CustomUserMessageBoxProps) {
  const customMessageType = (props.message as StreamMessage).customData?.type;

  if (customMessageType === 'SHOPIFY_PRODUCT') return <ShopifyProductMessageBox {...props} />;
  if (['SHOPIFY_WISH_BAG_REMINDER', 'SHOPIFY_CHECKOUT'].includes(customMessageType as string))
    return <ShopifyWishBagReminderMessageBox {...props} />;
  return null;
}
