import { useTheme } from '@mui/material';

import { ThemePreferences } from '../theme';

export const useStreamStyleOverrides = (themePreferences?: ThemePreferences) => {
  const theme = useTheme();

  const styleOverrides = {
    '--main-font': theme.typography.fontFamily,
    '--second-font': theme.typography.fontFamily,

    // '--primary-color': theme.palette.primary.dark,
    // '--primary-color-faded': theme.palette.primary.dark,
    // '--magenta': theme.palette.primary.dark,
    // '--red': theme.palette.warning.main,
    // '--faded-red': theme.palette.warning.dark,
    // '--border-radius': '15px',
    // '--spacing-unit': '16px',

    /* font size */
    '--xs-font': '10px',
    '--sm-font': '12px',
    '--md-font': '14px',
    '--lg-font': '16px',
    '--xl-font': '22px',
    '--xxl-font': '26px',
    '--xxxl-font': '32px',

    // /* fonts weight */
    // '--font-weight-regular:' #{$base-font-weight}; //400
    // '--font-weight-semi-bold:' 600;
    // '--font-weight-bold:' #{ $heavy - font - weight }; //700

    /* border-radius */
    '--border-radius': themePreferences?.noBorderRadius ? '0' : '15px',
    '--border-radius-sm': "calc(var('--border-radius') / 4)",
    '--border-radius-md': "calc(var('--border-radius') / 2)",
    '--border-radius-round': '999px',

    '--spacing-unit': '10px', // .5rem

    /* padding */
    '--xxs-p': "calc(var('--spacing-unit') / 2)", // 4px
    '--xs-p': "var('--spacing-unit')", // 8px
    '--sm-p': "calc(var('--spacing-unit') * 2)", // 16px Base
    '--md-p': "calc(var('--spacing-unit') * 3)", // 24px
    '--lg-p': "calc(var('--spacing-unit') * 4)", // 32px
    '--xl-p': "calc(var('--spacing-unit') * 5)", // 40px
    '--xxl-p': "calc(var('--spacing-unit') * 8)", // 64px

    //   /* margin */
    //   '--xxs-m': calc(var('--spacing-unit) / 2); // 4px
    //   '--xs-m': var('--spacing-unit); // 8px
    //   '--sm-m': calc(var('--spacing-unit) * 2); // 16px Base
    //   '--md-m': calc(var('--spacing-unit) * 3); // 24px
    //   '--lg-m': calc(var('--spacing-unit) * 4); // 32px
    //   '--xl-m': calc(var('--spacing-unit) * 5); // 40px
    //   '--xxl-m': calc(var('--spacing-unit) * 8); // 64px

    //   /* miscellaneous */
    //   '--assetsPath': '../assets';

    /* colors */
    '--primary-color': theme.palette.primary.main,
    '--primary-color-faded': theme.palette.primary.dark,

    '--magenta': theme.palette.primary.light,
    '--red': '#ff0000',
    '--faded-red': '#d0021b1a',

    '--dt-bg-team': 'green',

    '--border-color': theme.palette.primary.dark,

    '--lighten-black': theme.palette.grey[900],

    '--lighten-grey': theme.palette.grey[400],

    '--light-grey': theme.palette.grey[300],
    '--grey': theme.palette.grey[500],
    '--dark-grey': theme.palette.grey[700],

    '--green': '#28ca42',
    '--faded-green': '#02d0021a',

    //'--'-----------------------------White
    '--white': 'white',
    '--white5': '#ffffff0d',
    '--white10': '#ffffff1a;',
    '--white20': '#ffffff33',
    '--white30': '#ffffff4d',
    '--white40': '#ffffff66',
    '--white50': '#ffffff80',
    '--white60': '#ffffff99',
    '--white70': '#ffffffb3',
    '--white80': '#ffffffcc',
    '--white90': '#ffffffe6',
    '--white95': '#fffffff2',

    //'-------------------------------Black
    '--black': 'black',
    '--black5': '#0000000d',
    '--black10': '#0000001a',
    '--black20': '#00000033',
    '--black30': '#0000004d',
    '--black40': '#00000066',
    '--black50': '#00000080',
    '--black60': '#00000099',
    '--black70': '#000000b3',
    '--black80': '#000000cc',
    '--black90': '#000000e6',
    '--black95': '#000000f2',

    /* accent colors */
    '--accent_blue': '#005fff',
    '--accent_green': '#20e070',
    '--accent_red': '#ff3742',

    /* base colors */

    '--bg-gradient-end': '#f7f7f7',
    '--bg-gradient-start': '#fcfcfc',

    '--blue-alice': '#e9f2ff',
    '--border': '#00000014', // 14 = 8% opacity; top': x=0, y=-1; bottom': x=0, y=1
    '--button-background': '#ffffff',
    '--button-text': '#005fff',

    '--grey-gainsboro': '#dbdbdb',
    '--grey-whisper': '#ecebeb',
    '--highlight': '#fbf4dd',
    '--modal-shadow': '#00000099', // 99 = 60% opacity, x=0, y= 1, radius=4
    '--overlay': '#00000033', // 33 = 20% opacity
    '--overlay-dark': '#00000099', // 99 = 60% opacity
    '--shadow-icon': '#00000040', // 40 = 25% opacity, x=0, y=0, radius=4
    '--targetedMessageBackground': '#fbf4dd', // dark mode = #302D22
    '--transparent': 'transparent',

    '--white-smoke': '#f2f2f2',
    '--white-snow': '#fcfcfc',
  };

  return styleOverrides;
};
