import { ShopPlatform } from '@amazd/common/types';

export function useOpenProductPage(shopDomain?: string, shopPlatform?: string) {
  const openProductPage = (
    product: { openUrl?: string; handle?: string; id: string },
    variant?: { id: string; openUrl?: string },
  ) => {
    const openUrl = variant?.openUrl || product?.openUrl;
    if (openUrl) {
      window.open(openUrl);
      return;
    }
    let shopUrl = shopDomain;
    if (!shopUrl) return;
    if (!shopUrl.startsWith('http')) shopUrl = 'https://' + shopUrl;

    if (shopPlatform === ShopPlatform.MAGENTO) {
      if (!product.id) return;
      window.open(`${shopUrl}/catalog/product/view/id/${product.id}`);
    } else {
      // Support old API - Shopify specific
      let url = `${shopUrl}/products/${product.handle}`;
      if (variant?.id) {
        const parsedVariantIdArr = variant.id.split('/');
        const parsedVariantId = parsedVariantIdArr[parsedVariantIdArr.length - 1];
        url += `?variant=${parsedVariantId}`;
        window.open(url);
      }
    }
  };

  return { openProductPage };
}
