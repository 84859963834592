import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { Snackbar } from '@mui/material';
import StaticToast from 'components/StaticToast';
import { ToastSeverityType } from 'components/StaticToast/types';

interface SnackbarContextType {
  showSnackbar: (message: string, severity?: ToastSeverityType, title?: string, dismissable?: boolean) => void;
}

export const SnackbarContext = createContext<SnackbarContextType>({
  showSnackbar: () => {
    return;
  },
});

export const SnackbarProvider = ({ children }: PropsWithChildren<any>) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [severity, setSeverity] = useState<ToastSeverityType>('info');
  const [dismissable, setDismissable] = useState<boolean>(true);

  const showSnackbar = (message: string, severity: ToastSeverityType = 'info', title = '', dismissable = true) => {
    setMessage(message);
    setSeverity(severity);
    setTitle(title);
    setOpen(true);
    setDismissable(dismissable);
  };

  const handleClose = () => {
    setMessage('');
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ top: '72px', maxWidth: '500px', minWidth: 340 }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <StaticToast title={title} severity={severity} dismissable={dismissable} text={message} onClose={handleClose} />
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
