import { WishbagFragment } from '@amazd/common/hooks/useWishbagQuery';
import { gql, useMutation } from '@apollo/client';

export const useUpdateWishBagMutation = () => {
  const [updateWishBagEvent, { loading }] = useMutation(
    gql`
    mutation amazdWishbagUpdate($args: AmazdWishbagUpdateArgs!) {
      amazdWishbagUpdate(args: $args) {
        ${WishbagFragment}        
      }
    }
    `,
  );

  const updateWishBag = async (amazdId: string, data: any) => {
    return (
      await updateWishBagEvent({
        variables: {
          args: {
            amazdId,
            data,
          },
        },
      })
    ).data.amazdWishbagUpdate;
  };

  return {
    updateWishBag,
    loading,
  };
};
