import React from 'react';

import { Loader } from '@amazd/common/components';
import { Button } from '@mui/material';

import { ButtonProps } from './types';

const ButtonLoad: React.FC<ButtonProps> = ({ children, disabled, loading, ...rest }: ButtonProps) => {
  return (
    <Button disabled={loading ? true : disabled} {...rest}>
      {children}
      {loading && <Loader style={{ position: 'absolute' }} size={70} />}
    </Button>
  );
};

export default ButtonLoad;
