import { makeStyles } from '@mui/styles';
import { colors } from 'styles/theme';

export default makeStyles(() => ({
  wishBagWrapper: {
    width: 40,
    height: 40,
    border: 'solid',
    borderWidth: 1,
    borderColor: colors.grey30,
    borderRadius: 20,
    backgroundColor: colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  wishBag: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
